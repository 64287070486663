body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
.badge-info {
  background-color: #506456;
} */

.upload-images {
	background-color: #afafaf;
}

.card-body {
	padding: 1rem;
}

.card-footer {
	padding: 1rem;
}

.card-spacer {
	padding: 2rem;
}

.card-top {
	background-color: cornsilk;
}

.card-design-hints {
	border-top: 1px solid rgba(0, 0, 0, 0.125);
	background-color: rgb(255, 253, 245);
}

.coin-preview {
	flex: 0 0 195px;
	padding-left: 16px;
	padding-right: 0px;
	padding-top: 16px;
}

.coin-short {
	padding-top: 8px;
	padding-bottom: 8px;
}

.coin-stats-top {
	background-color: rgb(185, 185, 185);
	padding-top: 2px;
	padding-bottom: 4px;
	align-items: center;
}

.coin-stats-chart-container {
	display: flex;
}

.coin-stats-chart-container h5 {
	padding-top: px;
}

.zero-left-padding {
	padding-left: 2px;
	margin-left: 0px;
}

.coin-body {
	background-color: rgb(245, 252, 255);
}

.coin-body-stats {
	background-color: rgb(223, 223, 223);
}

.coin-nft-heading {
	border-top: 1px solid rgba(0, 0, 0, 0.125);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	background-color: rgb(160, 202, 207);
}

.coin-properties {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
	position: relative;
	padding: 0.5rem 1.25rem;
	margin-bottom: 0.5rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	box-sizing: border-box;
	display: block;
}

.coin-properties-button {
	color: #ffffff;
	text-transform: capitalize;
	font-weight: 500;
	background-color: #606c6e;
	border-color: #bee5eb;
	padding: 0.5rem;
	margin-bottom: 0.5rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	box-sizing: border-box;
	width: 100%;
}

.coin-properties-button-small {
	color: #ffffff;
	text-transform: capitalize;
	font-weight: 500;
	background-color: #606c6e;
	border-color: #bee5eb;
	padding: 6px;
	margin-bottom: 0.25rem;
	border: 0px solid transparent;
	border-radius: 0.25rem;
	width: 100%;
}

.stats-coverage {
	position: fixed;
	width: 100%;
	z-index: 50;
}

.stats-coverage-chart {
	padding: 4px;
	width: 200px;
	height: 200px;
}

.slider-text {
	font-size: small;
	text-transform: uppercase;
	font-weight: bold;
}

.slider-text.attack {
	color: #a00078;
}

.slider-text.defence {
	color: #00719e;
}

.slider-text.speed {
	color: #007e08;
}

.save-all-stats {
	background-color: #ffffff;
	padding: 6px;
	border-radius: 10px;
	box-shadow: 1px 1px 10px #0000005d;
	top: 70px;
	left: 10px;
	width: 100px;
	height: 50px;
	position: fixed;
}

.stats-warrior {
	background-color: #c0e6ff;
}

.stats-paladin {
	background-color: #0a5ce0;
}

.stats-knight {
	background-color: #133d75;
}

.stats-dark-knight {
	background-color: #211e3d;
}

.stats-dragoon {
	background-color: #3400c4;
}

.stats-mage {
	background-color: #ffc1e6;
}

.stats-sorcerer {
	background-color: #f52525;
}

.stats-summoner {
	background-color: #b6253b;
}

.stats-cleric {
	background-color: #eb33cc;
}

.stats-druid {
	background-color: #94008d;
}

.stats-rogue {
	background-color: #d0ffe5;
}

.stats-ranger {
	background-color: #1eac0e;
}

.stats-berserker {
	background-color: #00926e;
}

.stats-assassin {
	background-color: #004244;
}

.stats-monk {
	background-color: #007a83;
}

.coin-full-card {
	border: 1px solid rgb(129, 129, 129);
}

.coin-stats-card {
	margin: 10px;
	width: 400px;
}

.coin-images-card {
	margin: 10px;
	width: 350px;
	height: 290px;
}

.image-coin-stats {
	max-width: 195px;
	height: 195px;
}

.image-coin-thumbnail {
	max-width: 195px;
	height: 195px;
}

.image-coin-review {
	width: 256px;
	height: 256px;
}

.image-coin-1k {
	width: 1000px;
	height: 1000px;
}

.image-upload-thumbnail {
	max-width: 200px;
	height: 200px;
}

.a-whitetext {
	color: white;
}

.a-images {
	color: grey;
}
.a-images:hover {
	color: white;
	text-decoration: none;
}

div.mining {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 10;
	color: rgb(255, 255, 255);
	background-color: rgb(255, 81, 0);
	font-weight: bold;
	text-align: right;
	padding: 5px;
	padding-left: 10px;
	margin-right: 10px;
}

.erc20 {
	background-color: #c6e5ff;
}

.erc1155 {
	background-color: rgb(237, 221, 255);
}

.select-token {
	background-color: #fffde8;
}

.bet-info {
	margin-top: 2px;
	width: 59%;
	padding: 5px;
	color: rgb(140, 0, 255);
	background-color: #dddddd;
}

.content {
	margin: 4;
}

#loading {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 3px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top-color: #fff;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

/* cropper */
.pos-relative {
	position: relative;
	width: 100%;
	height: 100%;
}
.crop-wrapper {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.crop-container {
	position: relative;
	width: 592px;
	height: 592px;
}

.crop-download-button {
	background: lightblue;
	padding: 8px;
}

.crop-preview-1 {
	background-size: 518px 592px;
	background-image: url('https://ethemerals-media.s3.amazonaws.com/firebase_card_bg.jpg');
	position: relative;
	width: 518px;
	height: 592px;
}

.crop-container2 {
	position: relative;
	width: 300px;
	height: 450px;
}

.crop-preview-2 {
	width: 256px;
	height: 385px;
}

.crop-container3 {
	position: relative;
	width: 500px;
	height: 500px;
}

.crop-preview-3 {
	width: 388px;
	height: 112px;
}

.crop-container4 {
	position: relative;
	width: 150px;
	height: 200px;
}

.crop-preview-4 {
	width: 74px;
	height: 74px;
}

.controls {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 50%;
	transform: translateX(-50%);
	height: 80px;
	display: flex;
	align-items: center;
}

.slider {
	padding: 22px 0px;
}
